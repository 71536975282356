<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Emprunt" size="lg">
        <div class="text-center">
            <table>
                <tbody>
                <tr v-if="!isCurrentlyLoggedIn(emprunt.utilisateur)">
                    <td class="text-right"><strong>Utilisateur :</strong></td>
                    <td class="text-left">
                        <user-name :user="emprunt.utilisateur"/>
                    </td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Dates :</strong></td>
                    <td class="text-left">{{ formatDates(emprunt) }}</td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Statut :</strong></td>
                    <td class="text-left">
                        <b-badge :variant="getBadgeColorStatut(emprunt)"> {{ getTextStatut(emprunt) }}</b-badge>
                    </td>
                </tr>
                <tr>
                  <td class="text-right"><strong>Jeux de rôle :</strong></td>
                  <td class="text-left">
                    {{ emprunt.jdrs.map(({nom}) => nom).join(', ') }}
                  </td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Jeux de plateau :</strong></td>
                    <td class="text-left">
                        {{ emprunt.jeux.map(({nom}) => nom).join(', ') }}
                    </td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Livres :</strong></td>
                    <td class="text-left">
                        {{ emprunt.livres.map(({nom}) => nom).join(', ') }}
                    </td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Caution :</strong></td>
                    <td class="text-left">{{ cautionTotale }} €</td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Statut objets :</strong></td>
                    <td class="text-left">
                        <b-form-select v-model="statut" :options="statutOptions" :disabled="!isBureau()"></b-form-select>
                    </td>
                </tr>
                </tbody>
            </table>

            <b-row>
                <b-col class="text-left" lg="10" offset-lg="1">
                    <div v-if="emprunt.commentaireUtilisateur">
                        <hr/>
                        <h2>Commentaire utilisateur</h2>
                        <b-row>
                            <b-col cols="12">
                                <div class="ck-content" v-html="sanitize(emprunt.commentaireUtilisateur)"></div>
                            </b-col>
                        </b-row>
                    </div>

                    <div v-if="emprunt.commentaireBureau">
                        <hr/>
                        <h2>Commentaire du bureau</h2>
                        <b-row>
                            <b-col cols="12">
                                <div class="ck-content" v-html="sanitize(emprunt.commentaireBureau)"></div>
                            </b-col>
                        </b-row>
                    </div>

                    <hr/>
                </b-col>
            </b-row>

            <b-button @click="hideModal" variant="secondary">Ok</b-button>
        </div>
    </b-modal>
</template>

<script>
    const UserName = () => import('@/components/UserName');

    import demande               from '@/util/demande';
    import {isCurrentlyLoggedIn} from '@/util/auth';
    import {format2Dates}        from '@/util/date';
    import modalMixin                      from '@/mixin/modalMixin';
    import {cautionTotale, STATUT_OPTIONS} from '@/util/emprunt';
    import {isBureau}                      from '@/util/auth';
    import {apiPath}             from '@/util/http';

    import {sanitize} from 'dompurify';

    export default {
        name: "DisplayEmpruntModal",
        components: {UserName},
        mixins: [modalMixin],
        props: {
            emprunt: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayEmpruntModal',
            statut: null,
            statutOptions: STATUT_OPTIONS
        }),
        computed: {
            cautionTotale() {
                return cautionTotale(this.emprunt);
            }
        },
        watch: {
            statut() {
                if (isBureau()) {
                    this.axios
                        .post(
                            apiPath('edit_statut_emprunt_admin', {emprunt: this.emprunt.id}),
                            {statut: this.statut}
                        )
                        .then(() => this.callback())
                        .catch(() => this.$toaster.error(`Impossible de modifier la propriété "statut" de l'emprunt`));
                }
            }
        },
        methods: {sanitize, format2Dates, isCurrentlyLoggedIn, isBureau, ...demande},
        mounted() {
            this.statut = this.emprunt.statut;
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
    }

    td {
        padding-bottom: 0.5rem;
    }

    td.text-right {
        width: 25%;
    }

    td.text-left {
        padding-left: 10px;
    }
</style>